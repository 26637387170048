<template>
<v-container fluid>
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass">
            <v-icon large :color="contextStyle.titleColor" :class="contextStyle.titleIcon">{{ contextStyle.icon }}</v-icon>
            <span>{{ contextStyle.title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
    <v-layout wrap>
        <v-flex xs6 style="max-width:100%">
            <v-card class="elevation-1 ma-2">
                <v-toolbar flat dark color="primary" >
                    <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Export des taches</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-icon>mdi-download</v-icon>
                </v-toolbar>
                <v-container fluid grid-list-xl >
                <v-layout align-center wrap >
                    <v-flex xs12>
                        <v-menu ref="datebyconvention" v-model="dateMenu.date_byconvention"
                            :return-value.sync="dateReport.date_byconvention"
                            :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                            offset-y min-width="290px" style="z-index:1500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field 
                                    v-bind="attrs" :value="locDateFormat.formatLocale( dateReport.date_byconvention, 'fr' )"
                                    label="Depuis le" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                                </v-text-field>
                            </template>
                            <v-date-picker locale="fr-fr" no-title scrollable v-model="dateReport.date_byconvention">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="dateMenu.date_byconvention = false">Annuler</v-btn>
                                <v-btn color="primary" @click="$refs.datebyconvention.save( dateReport.date_byconvention )">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-flex>
                </v-layout>
                </v-container>
                <v-divider class="mt-5"></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn rounded color="success" :disabled="loading.getItem">
                        <v-icon left dark>mdi-content-save</v-icon>
                        <span class="hidden-sm-and-down">Telecharger</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-flex>
        <!-- <v-flex xs6 style="max-width:100%">
            <v-card class="elevation-1 ma-2">
                <v-toolbar flat dark color="primary" >
                    <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Export</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-icon>mdi-download</v-icon>
                </v-toolbar>
                <v-container fluid grid-list-xl >
                <v-layout align-center wrap >
                    <v-flex xs12>
                        
                    </v-flex>
                </v-layout>
                </v-container>
                <v-divider class="mt-5"></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn rounded color="success" :disabled="loading.getItem">
                        <v-icon left dark>mdi-content-save</v-icon>
                        <span class="hidden-sm-and-down">Telecharger</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-flex> -->
    </v-layout>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import { tskObj }  from '../mixins/tsk_obj.js'

export default {
    name: 'site_report',
    props: ['contextStyle'], 
    mixins: [ tskObj ],
    data: function () {
        return {
            locDateFormat: dateLocale, // Format de date
            loading: { getItem: false },
            dateMenu: { 
                date_byconvention   : null,
                date_byspecification: null,
                date_event_start    : null,
                date_event_end      : null,
                date_inv_start    : null,
                date_inv_end      : null,
            },
            dateReport : {
                date_byconvention   : null,
                date_byspecification: null,
                date_event_start    : null,
                date_event_end      : null,
                date_inv_start    : null,
                date_inv_end      : null,
            },
            items: [

            ],
            select: [

            ],
            state : cacheGetters.getTypesByTag('EVE', 'PRO'),
        }
    },
    mounted:function (){
       
    },
    methods: {
        downloadReport(report, option){
            this.loading.getItem = true
            let searchCriteria = this.getTaskFilter()
            for(let prop in option){
                if( option[prop] !== null && option[prop] !== undefined ){
                    searchCriteria += '&' + prop + '=' + option[prop]
                }
            }
            this.$http.customRequest({ method: 'get', url: '/report/' + report + '/' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "rapport.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.loading.getItem = false
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.loading.getItem = false
            })
        },
        downloadSite(){
            this.loading.getItem = true
            let searchCriteria = this.getTaskFilter()
            this.$http.customRequest({ method: 'get', url: '/report/tasks/' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "rapport.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.loading.getItem = false
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.loading.getItem = false
            })
        },
    },
    components: { }
}
</script>