import ApiService from './api'
import { TokenService } from './storage'


class AuthenticationError extends Error {
    constructor(errorCode, message) {
        super(message)
        this.name = this.constructor.name
        this.message = message
        this.errorCode = errorCode
    }
}

const UserService = {
    /**
     * Login the user and store the access token to TokenService. 
     * 
     * @returns access_token
     * @throws AuthenticationError 
    **/
    login: async function(login, password, grant_type = 'password') {
        const requestData = {
            method: 'post',
            url: "/accessToken",
            data: {
                client_id: 2,
                client_secret: process.env.VUE_APP_OAUTH_KEY,
                grant_type: grant_type,
                username: login,
                password: password
            }
        }

        try {
            const response = await ApiService.customRequest(requestData)
            
            TokenService.saveToken(response.data.access_token)
            TokenService.saveRefreshToken(response.data.refresh_token)
            ApiService.setHeader()
            
            // To refresh access token...
            ApiService.mount401Interceptor();

            return response.data.access_token
        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data.detail)
        }
    },

    /**
     * Refresh the access token.
    **/
    refreshToken: async function() {
        const refreshToken = TokenService.getRefreshToken()

        const requestData = {
            method: 'post',
            url: "/accessToken",
            data: {
                client_id: 2,
                client_secret: process.env.VUE_APP_OAUTH_KEY,
                grant_type: 'refresh_token',
                refresh_token: refreshToken
            }
        }

        try {
            const response = await ApiService.customRequest(requestData)

            TokenService.saveToken(response.data.access_token)
            TokenService.saveRefreshToken(response.data.refresh_token)
            // Update the header in ApiService
            ApiService.setHeader()

            return response.data.access_token
        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data.detail)
        }

    },

    /**
     * Logout the current user by removing the token from storage. 
     * Call api to revoke access / refresh tokens
     * Will also remove `Authorization Bearer <token>` header from future requests.
    **/
    logout: async function() {
        // Remove the token and remove Authorization header from Api Service as well 
        try {
            const response = await ApiService.get('/logout')
            
            TokenService.removeToken()
            TokenService.removeRefreshToken()
            ApiService.removeHeader()
            ApiService.unmount401Interceptor()

            return response.data
        } catch(error) {
            TokenService.removeToken()
            TokenService.removeRefreshToken()
            ApiService.removeHeader()
            ApiService.unmount401Interceptor()
            return error
        }
    }
}

export default UserService

export { UserService, AuthenticationError }