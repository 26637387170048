/* *****************************
    Objet : Task
******************************** */
import { cacheGetters } from "../store/cache.module"

export const tskObj = {
    data: function () {
        return {
            objTask: {
                tsk_id: '', 
                tsk_tre_id: '', 
                tsk_ses_id: '', 
                tsk_sta_id: 'GEN01', 
                tsk_typ_id: 'TSK01', 
                tsk_os: null, 
                tsk_typ_id_zone: 'TSZ01', 
                tsk_eve_id: null, 
                tsk_doc_id: null, 
                tsk_date_plan: null, 
                tsk_date_end: null, 
                tsk_date_due: null, 
                tsk_date_ess: null, 
                tsk_date_fou: null, 
                tsk_date_pro: null, 
                tsk_date_exec: null, 
                tsk_tour: '', 
                tsk_grid: '', 
                tsk_base_diam: '', 
                tsk_base_lenght: '', 
                tsk_base_width: '', 
                tsk_base_depth: '', 
                tsk_custom_fields: {}, 
                tsk_usr_id_created: '', 
                tsk_created_at: '',
                _table: {},
                _data: {  },
                _info: { tree: {}, document: {}, event: {} }
            },
            objTaskClone: {},
            listTask: [],
            metaTask: {},
            //bookmark: cacheGetters.getBookmark()
        }
    },
    computed: {
        
    },
    beforeMount: function (){
        this.objTaskClone = JSON.stringify( this.objTask )
    },
    methods: {
        getTaskFilter(page, perPage){
            page    = page || 1
            perPage = perPage || 'false'
            let filter = cacheGetters.getFilter()
            var searchCriteria = '?page='+ page +'&per_page=' + perPage + '&extended=withtree,withactivealert'
            for( var prop in filter ){
                if( filter[prop] !== null && filter[prop] !== undefined && filter[prop].length > 0 ){
                    searchCriteria += '&' + prop + '=' + filter[prop]
                }
            }
            return searchCriteria
        },
        getTaskShow(options){
            return new Promise( (resolve, reject) => {
                options = options || ''
                if( options.length > 0 ){
                    options = '/?include=' + options
                }
                this.$http.get( '/tasks/' + this.objTask.tsk_id + options).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( typeof response.data.data[prop] === 'object' && response.data.data[prop] !== null && response.data.data[prop]['data'] !== undefined ){
                            this.objTask._info[prop] = response.data.data[prop]['data']
                        } else {
                            this.objTask[prop] = response.data.data[prop]
                        }
                    }
                    this.objTask.tsk_custom_fields = (!this.objTask.tsk_custom_fields) ? {} : this.objTask.tsk_custom_fields
                    if( Object.prototype.toString.call( this.objTask.tsk_custom_fields ) === '[object Array]' ) {
                        this.objTask.tsk_custom_fields = {}
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getTaskIndex(page, perPage, options){
            return new Promise( (resolve, reject) => {
                options = options || ''
                this.listTask = []
                //let searchCriteria = this.getTaskFilter(page, perPage)

                this.$http.get( '/tasks/' + options ).then( (response) => {
                    this.metaTask = response.data.meta
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.listTask = response.data.data
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        postTaskStore(){
            return new Promise( (resolve, reject) => {
                this.$http.post( '/tasks', this.objTask ).then( (response) => {
                    this.objTask.tsk_id = response.data.data.tsk_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        putTaskStore(obj){
            return new Promise( (resolve, reject) => {
                obj = (obj) ? obj : this.objTask // partial update
                this.$http.put( '/tasks/' + obj.tsk_id, obj ).then( (response) => {
                    this.objTask.tsk_id = response.data.data.tsk_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        deleteTaskDestroy(){
            return new Promise( (resolve, reject) => {
                this.$http.delete( '/tasks/' + this.objTask.tsk_id ).then( (response) => {
                    this.objTask.tsk_id = response.data.tsk_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        checkTaskState(obj){
            return new Promise( (resolve) => {
                obj = (obj) ? obj : this.objTask
                if( obj.tsk_date_pro && obj.tsk_date_pro !== '0000-00-00' ){
                    this.objTask.tsk_typ_id = 'TSK05'
                } else if( obj.tsk_date_fou && obj.tsk_date_fou !== '0000-00-00' ){
                    this.objTask.tsk_typ_id = 'TSK04'
                } else if( obj.tsk_date_ess && obj.tsk_date_ess !== '0000-00-00' ){
                    this.objTask.tsk_typ_id = 'TSK03'
                } else if( obj.tsk_date_plan && obj.tsk_date_plan !== '0000-00-00' ){
                    this.objTask.tsk_typ_id = 'TSK02'
                } else {
                    this.objTask.tsk_typ_id = 'TSK01'
                }
                obj.tsk_typ_id = this.objTask.tsk_typ_id
                resolve(obj)
            })
        },
        cloneTaskObj(){
            this.objTaskClone = JSON.stringify( this.objTask )
        },
        cleanTaskObj(){
            var taskClone = JSON.parse( this.objTaskClone )
            var prop
            for( prop in taskClone ){
                if( this.objTask[prop] !== undefined ){
                    this.objTask[prop] = taskClone[prop]
                }
            }
        },
    }
}