import Vue from "vue";
import { UserService, AuthenticationError } from '../services/user'
import { TokenService } from '../services/storage'
import router from '../services/router'


export const state = Vue.observable({
    authenticating: false,
    accessToken: TokenService.getToken(),
    authenticationErrorCode: 0,
    authenticationError: '',
    refreshTokenPromise: null  // Holds the promise of the refresh token
});

export const getters = {
    loggedIn: (state) => {
        return state.accessToken ? true : false
    },

    authenticationErrorCode: (state) => {
        return state.authenticationErrorCode
    },

    authenticationError: (state) => {
        return state.authenticationError
    },

    authenticating: (state) => {
        return state.authenticating
    }
}

export const actions = {
    async login({login, password, grant_type}, redirect=true) {
        mutations.loginRequest()

        try {
            const token = await UserService.login(login, password, grant_type)
            mutations.loginSuccess(token)

            // Redirect the user to the page he first tried to visit or to the home view
            if( redirect ){
                router.push(router.history.current.query.redirect || '/');
            }

            return true
        } catch (e) {
            if (e instanceof AuthenticationError) {
                mutations.loginError( {errorCode: e.errorCode, errorMessage: e.message} )
            }

            return false
        }
    },

    async logout() {

        try {
            const response = await UserService.logout()
            mutations.logoutSuccess()

            //router.push('/login')
            return response
        } catch (e) {
            mutations.loginError( {errorCode: e.errorCode, errorMessage: e.message} )
            return false
        }
    },

    refreshToken() {
        // If this is the first time the refreshToken has been called, make a request
        // otherwise return the same promise to the caller
        if(!state.refreshTokenPromise) {
            const p = UserService.refreshToken()
            mutations.refreshTokenPromise(p)

            // Wait for the UserService.refreshToken() to resolve. On success set the token and clear promise
            // Clear the promise on error as well.
            p.then(
                response => {
                    mutations.refreshTokenPromise(null)
                    mutations.loginSuccess(response)
                },
                () => {
                    //error
                    mutations.refreshTokenPromise(null)
                }
            )
        }

        return state.refreshTokenPromise
    }
}

export const mutations = {
    loginRequest() {
        state.authenticating          = true;
        state.authenticationError     = ''
        state.authenticationErrorCode = 0
    },

    loginSuccess(accessToken) {
        state.accessToken    = accessToken
        state.authenticating = false;
    },

    loginError({errorCode, errorMessage}) {
        state.authenticating = false
        state.authenticationErrorCode = errorCode
        state.authenticationError = errorMessage
    },

    logoutSuccess() {
        state.accessToken = ''
    },

    refreshTokenPromise(promise) {
        state.refreshTokenPromise = promise
    }
}
