import Vue from "vue";

export const scopeCredential = Vue.observable({

        dashboard: {
            read:        ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTERN_USER'],
            edit:        ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            lastsheet:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            lastevent:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        },
        task: {
            read:        ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTERN_USER'],
            edit:        ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            lastsheet:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            lastevent:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        },
        event     : {},
        eventedit : {
            read:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTERN_USER'],
            edit:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            delete: ['ADMIN_USER', 'SUPER_USER']
        },
        map : {},
        document : {
            read:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            edit:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            delete: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
        },
        report : {},
        reportext : {},
        // Params
        users: {
            read: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
        },
        useredit: {
            read: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            edit: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
        },
        states: {},
        stateedit: {
            read: ['ADMIN_USER', 'SUPER_USER'],
            edit: ['ADMIN_USER', 'SUPER_USER']
        },
        types: {},
        typeedit: {
            read: ['ADMIN_USER', 'SUPER_USER'],
            edit: ['ADMIN_USER', 'SUPER_USER']
        },
        contacts: {
            read:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            edit:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            delete: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
        },
        contacts_view: {
            read:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            edit:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            delete: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
        },
        jobs     : {
            read:   ['ADMIN_USER', 'SUPER_USER' ],
            edit:   ['ADMIN_USER', 'SUPER_USER' ],
            delete: ['ADMIN_USER', 'SUPER_USER' ]
        },
})

export const scopeGetters = {
    getAccess: () => {
      return scopeCredential
    },
}

export const scopeMutations = {
    setAccess( access ) {
        let prop
        for( prop in access ){
            scopeCredential[prop] = access[prop];
        }
    },
}