import Vue from 'vue'
import dayjs from 'dayjs'
require('dayjs/locale/fr')
// Object.defineProperties(Vue.prototype, {
//     $date: {
//         get() {
//             return dayjs
//         }
//     }
// });
dayjs.locale('fr')

Object.defineProperty(Vue.prototype, '$date', { value: dayjs })
