<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-tabs v-model="activeTab">
           <v-tab>Arbres</v-tab>
           <v-tab>Synchro</v-tab>
        </v-tabs>
        <v-spacer></v-spacer>
    </v-toolbar>
    <v-layout v-if="activeTab === 0" wrap >
        <v-flex xs6 style="max-width:100%">
            <v-card class="ma-2 pa-5 elevation-2">    
            <v-toolbar flat dark color="primary" >
                <v-toolbar-title>
                    <v-icon>mdi-code-equal</v-icon>
                    Import des arbres
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <span class="caption">Dernier import :</span>
                <v-chip small outlineed label light color="white">
                    &nbsp; 2019/03/09
                </v-chip>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-file-input @change="getFile($event, 'task')" ref="task" v-model="form.file_task" show-size label="Fichier(s)"></v-file-input>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-btn rounded color="grey" @click="downloadModel('nord')" :disabled="saveProgress">
                    <v-icon left dark>mdi-cloud-download</v-icon>
                    <span class="hidden-sm-and-down">Modèle</span>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" @click="sendSynchFile('task')" :disabled="saveProgress">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Envoyer</span>
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-flex>
        <v-flex xs12 style="max-width:100%">
            <v-card class="ma-2 pa-5 elevation-2">    
            <v-alert border="top" colored-border type="info" elevation="2" >
                <v-list dense>
                    <v-subheader v-if="processInfo.error_file">
                        <v-btn rounded color="success" :disabled="false" @click="downloadError()">
                            <v-icon left dark>mdi-content-save</v-icon>
                            <span class="hidden-sm-and-down">Telecharger le rapport</span>
                        </v-btn>
                    </v-subheader>
                    <v-subheader v-else>RAPPORT</v-subheader>
                    <v-list-item-group color="primary" >
                        <v-list-item v-for="(item, i) in processInfo.error" :key="i" >
                        <!-- <v-list-item-icon> <v-icon v-text="item.icon"></v-icon> </v-list-item-icon> -->
                        <v-list-item-content>
                            <v-list-item-title v-text="item"></v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-alert>
            </v-card>
        </v-flex>
    </v-layout>

<!-- Formulaire -->
    <v-card v-if="activeTab === 1" fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
            <v-flex xs12 style="max-width:100%" v-if="sync_error">
                <v-card class="ma-2 pa-5 elevation-2">    
                <v-alert border="top" colored-border type="info" elevation="2" >
                    <v-list dense>
                        <v-subheader>Erreur</v-subheader>
                        <v-list-item-group color="primary" >
                            <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title v-text="sync_error_message"></v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-alert>
                </v-card>
            </v-flex>
            <template v-for="(item, index) in syncRow">
            <v-flex xs10 class="pa-2" :key="index">
            <v-alert v-model="syncRow[index].display" dismissible 
                :color="syncRow[index].color" 
                border="left" elevation="2" colored-border :icon="(syncRow[index].sync) ? 'mdi-sync' : 'mdi-power-standby'" class="mb-1">
                {{ item.message }}
                <!-- <v-chip outlined color="blue">{{ locDateFormat.formatLocale('2020-01-01', 'fr') }}</v-chip> -->
                <template v-for="(itr, idxr) in item.result">
                    <span :key="idxr"><br />
                    <v-chip outlined color="green">{{itr.table}}</v-chip>
                    Source: {{itr.source}} | Synchro: {{itr.sync}} | Insert: {{itr.insert}}</span>
                </template>
            </v-alert>
            </v-flex>
            <v-flex xs2 class="pa-2" :key="index + 'start'">
                <v-btn v-show="!sync_global" class="mt-3" color="success" :loading="saveProgress" @click.native="syncTable(syncRow[index])" :disabled="saveProgress">
                    Demarrer
                </v-btn>
            </v-flex>
            </template>
        </v-layout>
    </v-card>

</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';

export default {
    name: 'synchro',
    props: [],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            sync_progress: 0,
            sync_global  : 0,
            sync_complete: false,
            sync_error: false,
            sync_error_message: '',
            locDateFormat: dateLocale, // Format de date
            activeTab: 0,
            processInfo: { error : [ 'Information sur le traitement...' ]},
            form: {
                file_task: null,     file_task_name: '',     file_task_source: '',
                file_specification: null, file_specification_name: '', file_specification_source: '',
            },
            syncRow : [
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/trees', message: 'Arbres' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/sessions', message: 'Sessions' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/tasks', message: 'Taches' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/users', message: 'Utilisateurs' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/opentree', message: 'Open API' },

            ],
        }
    },
    computed: {
        progress () {
            return Math.min(100, this.form.sync_progress.length * 12.5)
        },
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.sync_progress / 50)]
        }
    },
    mounted:function (){

    },
    methods: {
        getFile(file, scope){
            if( this.form['file_' + scope + '_name'].length == 0 ){
                let nameTab = file.name.split(".")
                this.form['file_' + scope + '_name'] = nameTab[0]
            }
            this.form['file_' + scope + '_name'] = file.name
        },
        async sendSynchFile(scope) {
            // Enregistrement du formulaire
            this.saveProgress = true
            var formFile = new FormData()
            for (var prop in this.form) {
                if( prop == scope ){
                    formFile.append(prop, JSON.stringify( this.form[prop] ))
                } else {
                    formFile.append(prop, this.form[prop])
                }
            }
            if( this.form['file_' + scope] ) {
                formFile.append('file_' + scope, this.form['file_' + scope], this.form['file_' + scope].name)
            }
            this.$http.post( '/import/' + scope + '/', formFile )
            .then( (response) => {
                this.$root.$toast({ color: 'success', text: 'Données enregistrée !' })
                this.saveProgress = false
                for( let prop in response.data ){
                    if( Array.isArray( response.data[prop] ) ){
                        this.processInfo[prop] = response.data[prop]
                    } else {
                        this.processInfo[prop] = response.data[prop]
                    }
                }
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                this.saveProgress = false
            })
        },
        downloadError(){
            this.$http.customRequest({ method: 'get', url: 'import/reportfile/' + this.processInfo.error_file , responseType: 'blob' } )
            .then( (response) => {
                let filename      = 'd.pdf'
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(response.headers['content-disposition']);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
                if( response.status == 204 ){
                    this.$root.$toast({ color: 'warning', text: 'Ce fichier n\'existe pas' })
                } else {
                    this.$download(response.data, filename, response.headers['content-type'])
                }
            }).catch( (error) => {
                this.$root.$toast({ color: 'error', text: error })
            })
        },
        downloadModel(){
            this.$http.customRequest({ method: 'get', url: 'import/model/' + 'TSZ01', responseType: 'blob' } )
            .then( (response) => {
                let filename      = 'd.pdf'
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(response.headers['content-disposition']);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
                if( response.status == 204 ){
                    this.$root.$toast({ color: 'warning', text: 'Ce fichier n\'existe pas' })
                } else {
                    this.$download(response.data, filename, response.headers['content-type'])
                }
            }).catch( (error) => {
                this.$root.$toast({ color: 'error', text: error })
            })
        },
        syncTable(table){
            this.$http.post( table.endPoint, {} )
            .then( () => {
                this.$root.$toast({ color: 'success', text: 'Données enregistrée !' })
                this.saveProgress = false
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                this.saveProgress = false
            })
        }
    },
    components: { }
}
</script>