import L    from 'leaflet';
// eslint-disable-next-line no-unused-vars
import MarkerCluster from 'leaflet.markercluster'
// eslint-disable-next-line no-unused-vars
import LeafleatPm from 'leaflet.pm';
import { bus } from '@/plugins/bus'
// Turf tools
import turfWithinPolygon  from '@turf/points-within-polygon'
import * as turfHelpers   from '@turf/helpers'

class LfMap {
    constructor(mapContainer) {
      this.layerUrl = 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'
      this.mapContainer = mapContainer
      this.baseLayerMap = this.setLayer('base')
      this.Map          = null
      this.arrayOfLatLngs = []
      this.leaflet       = window.L
      this.defautLatLng  = [47.47479783478821, 1.0530929565429689]
      this.markerLoc     = [0, 0]
      this.markerDrag    = null
      this.circleMarker  = null
      this.partMarkers   = L.layerGroup()
      this.svgIcon       = {
        green: '<div class="marker-svg marker-green"><center></center></div>',
        blue: '<div class="marker-svg marker-blue"><center></center></div>',
        orange: '<div class="marker-svg marker-orange"><center></center></div>',
        red: '<div class="marker-svg marker-red"><center></center></div>',
        truck: '<div class="marker-svg marker-truck"><center></center></div>',
        gen: '<div class="marker-svg marker-gen"><center></center></div>'
      };
      /************* LAYERS *************/
      this.baseLayer     = {}
      this.layerDistrict = L.layerGroup()
      this.layerDraw     = L.layerGroup()
      this.layerAlert    = L.layerGroup()
      this.layerTree     = L.layerGroup()
      this.layerTask     = L.layerGroup()
      // Emprise zone
      this.layerEmprise = L.layerGroup();
      // Emprise par arbre
      this.layerTreeEmprise = L.layerGroup();
      this.clusterTree   = L.markerClusterGroup({ disableClusteringAtZoom: 16 })
      this.clusterTask   = L.markerClusterGroup({ disableClusteringAtZoom: 16 })
      /************* BLOCKS *************/
      this.blocks        = { origin: {}, generic: {}, default: {} }
      /************* GEOJSON *************/
      this.geoJson       = {}
      /************* ******* *************/
      this.vehicleTo     = undefined
      /************* ******* *************/
      this.modeAlert      = false
      this.modeDict       = false
      this.mapCenter      = null
      this.zoom           = 6
      this.currentBound   = null
      this.taskMarker     = {}
      this.francePolygon  = {}

      this.turfHelpers   = turfHelpers
    }

    display(latlng, zoom, projection) {
      latlng     = latlng || this.defautLatLng
      zoom       = zoom   || 6
      this.zoom  = zoom
      projection = projection || 'EPSG3857'
      this.projection = projection;
      var mapOptions = {
          zoom: zoom,
          zoomControl:false,
          center: latlng,
          crs: L.CRS[ projection ],
          preferCanvas: true,
          tapTolerance: 20,
          maxZoom : 28, minZoom: -20, maxNativeZoom: 16,
          layers: [this.baseLayerMap],
      };

      if( latlng !== undefined ){
          mapOptions.center = latlng
      }
      if( zoom !== undefined ){
          mapOptions.zoom = zoom
      }
      // Init MAP
      this.Map = new this.leaflet.map(this.mapContainer, mapOptions);
      
      this.layerDistrict.addTo(this.Map)
      this.layerDraw.addTo(this.Map)
      this.clusterTask.addTo(this.Map)
      this.clusterTree.addTo(this.Map)
      this.layerTask.addTo(this.Map)
      this.layerAlert.addTo(this.Map)

      this.layerEmprise.addTo(this.Map)
      this.layerTreeEmprise.addTo(this.Map)

      /************ Action Dessin **********************/
      this.Map.on('pm:create', (e) => {
        setTimeout( () => {
          if( this.modeDict ){
            this.flagMarkerInPoly( e.layer )
          } else {
            bus.$emit( 'task:select', e.layer.getBounds() )
          }
          this.Map.removeLayer(e.layer)

        }, 600)
      });
      /************ Action sur zoom carte **********************/
      this.Map.on('moveend', () => {
        this.zoom         = this.Map.getZoom();
        bus.$emit('tree:get', this.zoom)
      });
      /************ Action sur click carte **********************/
      this.Map.on('click', (e) => {
        if( this.modeAlert ){
          this.addLocMarker( { lat: e.latlng.lat, lng: e.latlng.lng } );
        }
      });

      if( Object.prototype.toString.call( this.mapCenter ) === '[object Array]' ){
        this.Map.setView(new L.LatLng(this.mapCenter[0], this.mapCenter[1]), 20);
      }

      var latlngs = [[42.502779, -7.326439],[42.502779, 10.30831],[53.147256, 10.30831],[53.147256, -7.326439]];
      this.francePolygon = L.polygon(latlngs, {color: 'red'}); //.addTo(this.Map)

      return this.Map;
    }

    enablePmDraw(tool) {
      this.Map.pm.enableDraw(tool, { snappable: false });
    }

    locateMe(){
        var self = this;
        this.Map.locate({setView: false, watch: true, enableHighAccuracy: true}).on( 'locationfound', function(e){
          if( parseInt(e.accuracy) > 500 ){
            self.focusOnUser( { latlng: { lat: 48.818854, lng: 2.319438 } }, 15 );
          } else {
            self.focusOnUser(e);
          }
        });
    }

    setLayer(value) {
      switch(value) {
        case 'base':
          return this._setBaseLayer();
        case 'offline':
          return this._setOfflineLayer();
          case 'notile':
          return this._setNoTileLayer();
        default:
          return this._setBaseLayer();
      }
    }

    // Ajouter une zone (cercle)
    addCircleZone(pos){
      if( pos.lat == 0 || pos.lng == 0 || pos.lat == undefined ){
        pos.lat = this.defautLatLng[0];
        pos.lng = this.defautLatLng[1];
      }
      if( this.circleMarker == null || this.circleMarker == undefined ){
        this.circleMarker = L.circle([pos[1], pos[0]], {
            color: '#2196F3',
            fillColor: '#2196F3',
            fillOpacity: 0.5,
            radius: 20
        }).addTo(this.Map);
      } else {
        this.circleMarker.setLatLng( [pos[1], pos[0]] );
      }
      setTimeout( () => {
        this.Map.removeLayer(this.circleMarker)
      }, 1500);
    }

    panTo(pos, zoom){
      zoom = zoom || 19;
      if( pos.lat !== undefined ){
        pos[1] = pos.lat;
        pos[0] = pos.lng;
      }
      this.Map.setView( [pos[1], pos[0]], zoom, {
        pan: { animate: true, duration: 1.50 },
        zoom: { animate: true }
      });
    }

    // Ajout de taches existante
    addTask(taskObj, option){
      option = option || 'task'
      if( !taskObj.tre_lat || !taskObj.tre_lng ){
        taskObj.tre_lat = 48.8582
        taskObj.tre_lng = 2.3387
      }
      let obj = { draggable: false, marker:0, type: 'gen', color: taskObj.color, latLng: [taskObj.tre_lat, taskObj.tre_lng] }
      obj.marker = ( taskObj.tsk_date_due && taskObj.tsk_date_due.length > 4 ) ? 'date_due' : obj.marker
      obj.marker = ( taskObj.eve_total ) ? 'warning' : taskObj.eve_total
      obj.type = ( option == 'dict' ) ? 'grey-dict' : obj.type

      var marker      = this.buildMarker( obj )
      marker.selected = false
      marker._taskObj = taskObj
      marker.on('click', (e) => {
        if( option == 'dict' ){
          marker.selected = !marker.selected
          let type = ( marker.selected ) ? 'grey-selected' : 'grey-dict'
          marker.setIcon( this.buildIcon( { type: type } ) )
        } else {
          bus.$emit('task:open', { tsk_id: taskObj.tsk_id, tsk_lat: e.latlng.lat, tsk_lng: e.latlng.lng })
        }
      })

      if( !this.francePolygon.getBounds().contains( marker.getLatLng() ) ){
        marker.setLatLng( new L.LatLng(48.855824, 2.349568) );
      }
      this.clusterTask.addLayer(marker);
      this.taskMarker[taskObj.tsk_id] = marker //this.clusterTask.getLayerId(marker)
      //this.layerAlert.addLayer( marker );
      //this.layerAlert['alt' + alertObj.alt_id ] = marker.addTo(this.Map);
    }

    updateTask(taskObj){
      let obj = { draggable: false, marker:0, type: 'gen', color: taskObj.color, latLng: [taskObj.tre_lat, taskObj.tre_lng] }
      if( taskObj.tsk_date_due && taskObj.tsk_date_due.length > 4 ){
        obj.marker = 'date_due'
      }
      if( taskObj.eve_total ){
        obj.marker = 'warning'
      }
      if( this.taskMarker[taskObj.tsk_id] ){
        let icon = this.buildIcon(obj)
        this.taskMarker[taskObj.tsk_id].setIcon(icon)
      } else {
        this.addTask(taskObj)
      }
    }

    fitBoundLayerGroup(){
      try {
        this.Map.fitBounds(this.clusterTask.getBounds(), { maxZoom: 18 }); 
      } catch (error) {
        console.log(error)
      }
    }

    removeAllTask(){
      this.clusterTask.eachLayer( layer => {
        this.clusterTask.removeLayer(layer)
      })
    }

    // Ajout de taches existante
    addTree(treeObj){
      var marker = this.buildMarker( { draggable: false, type: 'gen', color: treeObj.color, latLng: [treeObj.tre_lat, treeObj.tre_lng] } );
      marker.on('click', (e) => {
        bus.$emit('task:open', { tre_id: treeObj.tre_id, tsk_lat: e.latlng.lat, tsk_lng: e.latlng.lng })
      })
      this.clusterTask.addLayer(marker);
    }

    removeAllTree(){
      this.clusterTree.eachLayer( layer => {
        this.clusterTree.removeLayer(layer)
      })
    }

    // Ajout d'alerte existante
    addAlert(alertObj){
      var marker = this.buildMarker( { draggable: false, type: 'orange-warning', latLng: [alertObj.eve_lat, alertObj.eve_lng] } );
      marker.on('click', (e) => {
        bus.$emit('alert:open', { eve_id: alertObj.eve_id, eve_lat: e.latlng.lat, eve_lng: e.latlng.lng })
      })
      this.layerAlert.addLayer( marker );
      this.layerAlert.addTo(this.Map);
    }
    removeAllAlert(){
      this.layerAlert.eachLayer( layer => {
        this.layerAlert.removeLayer(layer)
      })
    }

    displayDistrict(geojson, state){
      function onEachFeature(feature, layer) {
        // does this feature have a property named popupContent?
        if (feature.properties && feature.properties.L_AR) {
          layer.bindPopup(feature.properties.L_AR);
        } else { layer.bindPopup('Aucune info'); }
      }
      if( state ){
        this.layerDistrict = L.geoJson(geojson, {
          onEachFeature: onEachFeature,
          style: {"weight":2, "fillOpacity": 0.2, "fillColor":"#808080", "opacity": 1, "color":"#0078CE"}
        }).addTo(this.Map);
      } else {
        this.Map.removeLayer(this.layerDistrict);
      }
    }

    // Activer lr mode alert
    activeAlert( mode ){
      mode = mode || false
      this.modeAlert = mode;
      this.markerDrag = null
    }

// Flag des markers dans le polygon
  flagMarkerInPoly( poly ) {
    this.clusterTask.eachLayer( (marker) => {
      let inPolygon = turfWithinPolygon( marker.toGeoJSON(), poly.toGeoJSON())
      if( inPolygon.features.length ){
        marker.selected = true
        marker.setIcon( this.buildIcon( { type: 'grey-selected' } ) )
      }

    })
  }
/*************************************************************************** */
/******************** Heplers ************************************************/
/*************************************************************************** */
    // Ajouter un marqeur de géolocalisation
    addLocMarker(pos) {
      if( pos.lat == 0 || pos.lng == 0 ){
        pos.lat = this.defautLatLng[0];
        pos.lng = this.defautLatLng[1];
      }
      if( ( this.markerDrag == null || this.markerDrag == undefined ) && !this.modeTree ){
        this.markerDrag = this.buildMarker( { draggable: true, type: 'orange-warning', latLng: [pos.lat, pos.lng] } );
        this.layerAlert.addLayer( this.markerDrag );
        // Open alert on click
        if( this.modeAlert ){
          this.markerDrag.on('click', (e) => {
            bus.$emit('alert:open', { eve_id: 0, eve_lat: e.latlng.lat, eve_lng: e.latlng.lng })
          })
        }
      } else if( this.modeTree ){
        this.markerDrag = this.buildMarker( { draggable: true, type: 'blue', latLng: [pos.lat, pos.lng] } );
        this.layerTask.addLayer( this.markerDrag );
      } else {
        this.markerDrag.setLatLng( new this.leaflet.LatLng(pos.lat, pos.lng) );
      }
      //this.Map.setView( new this.leaflet.LatLng(pos.lat, pos.lng), 9 );
    }

    buildMarker(obj) {
      let title     = obj.title || '';
      let popup     = obj.popup || '';
      let draggable = obj.draggable || false
      let marker    = L.marker(obj.latLng, {
        title: title,
        icon: this.buildIcon(obj),
        draggable: draggable
      });
      marker.on('dragend', () => {
        var position = marker.getLatLng();
        this.markerLoc = [ position.lat, position.lng ];
      });
      if( popup.length > 0 ){
        marker.bindPopup(popup);
      }
  
      return marker;
    }

    buildIcon(obj) {
      let type = obj.type;
      let html = '<div class="marker-svg marker-blue"><center></center></div>';
      switch(type) {
        case 'blue':
          html = this.svgIcon['blue'];
          break;
        case 'truck':
          html = this.svgIcon['truck'];
          break;
        case 'gen':
          if( obj.color ){
            html = '<div><svg style="fill:' + obj.color + ';" xmlns="http://www.w3.org/2000/svg" viewBox="1239.2 -915.6 332.5 593">';  
          } else {
            html = '<div><svg xmlns="http://www.w3.org/2000/svg" viewBox="1239.2 -915.6 332.5 593">';  
          }
          html += '<path opacity=".2" d="M1544.6-459c-46.1-15.4-305.3-36.5-305.3 13.4v3.8c0 48 163.2 119.1 163.2 119.1 184.3-99.8 193.9-119 142.1-136.3z"/>';
          html += '<path d="M1398.6-339.9s-159.4-272.7-159.4-458.9 251.6-107.5 297.6-49.9c50 63.3 40.4 140.1-138.2 508.8z" />';
          html += '<path fill="#000" opacity=".3" d="M1398.6-339.9s-159.4-272.7-159.4-458.9 251.6-107.5 297.6-49.9c50 63.3 40.4 140.1-138.2 508.8z" />';
          html += '<path d="M1398.6-339.9s-159.4-265-159.4-447.4 234.3-105.6 276.5-49.9c48.1 61.4 55.7 97.9-117.1 497.3z" />';
          html += '<circle fill="#FFF" stroke="#33756E" stroke-width=".4" stroke-miterlimit="10" cx="1392.9" cy="-731.7" r="71" /></svg>';
          if( obj.marker ){
            html += '<img src="./' + obj.marker + '.svg" style="width: 25px; margin: 0 auto; position:absolute; top:4px; right:6px;">';
          }
          html += '<div>';
          break;
        default:
          var p = type.split("-");
          if( p[1] !== undefined ){
            html= '<div class="marker-svg marker-' + p[0] + '"><img src="./' + p[1] + '.svg" style="width: 25px; margin: 0 auto; position:absolute; top:4px; right:6px;"></div>';
          } else {
            html = this.svgIcon['blue'];
          }
      }
      
      return this.leaflet.divIcon({ 
        html: html, 
        iconSize: [34, 56], 
        iconAnchor: [17, 55], 
        popupAnchor: [0, -56],
        className: 'marker-transp'
      });
    }

    _setBaseLayer() {
      return L.tileLayer('https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/osmtiles/webmercator/{z}/{x}/{y}.png', { //this.layerUrl
        attribution: 'TECHNOSIG',
        maxNativeZoom: 18, maxZoom : 24,
        id: 'osm'
      });
    }
    _setOfflineLayer() {
      return L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', { //this.layerUrl
        attribution: 'TECHNOSIG - Offline',
        id: 'osm cache'
      });
    }
    _setNoTileLayer() {
      return L.tileLayer('', { //this.layerUrl
        attribution: 'TECHNOSIG - Offline',
        id: 'osm cache'
      });
    }
    setTileLayer( link ){
      this.Map.removeLayer(this.baseLayerMap);
      this.baseLayerMap = L.tileLayer(link, {
        attribution: 'TECHNOSIG',
        id: 'osm',
        maxZoom : 28, minZoom: -20, maxNativeZoom: 18
      });
      this.Map.addLayer(this.baseLayerMap);
    }
  
    setInitialCenter(){
      this.Map.setView(this.defautLatLng)
      this.Map.setZoom(6); //this.Map.panTo(this.defautLatLng, {animate: true, duration: 1.0});
    }

  // dessin des polygon
  mapAutoDrawPoly() {
    var selMark = [];
    var m       = 0;
    this.clusterTask.eachLayer( (marker) => {
      if( marker.selected ){
          marker._iGoup  = false;
          marker._nInter = 0;
          selMark.push(marker);
            m += 1;
      }
    });
    // Tri par position / address
    selMark.sort(function(a, b) {
        return parseFloat(parseFloat(a._latlng.lng - b._latlng.lng) );
    });    
    // Construction des zones ------------------------
    var zonSize   = 100; // Taille de travail en mètres
    var zonAdjust = 5; // Ajustement pour bien englober les points
    var km, val;
    var maxLng = 0, minLat = 0;
    var maxLat = 99, minLng = 99; // minLat,minLng=Topleft -- maxLat,maxLng=BottomRight
    for (km = 0; (val = selMark[km]) !== undefined; km++) {
        if( val._latlng.lat > minLat ){ minLat = val._latlng.lat; }
        if( val._latlng.lng < minLng ){ minLng = val._latlng.lng; }
        if( val._latlng.lat < maxLat ){ maxLat = val._latlng.lat; }
        if( val._latlng.lng > maxLng ){ maxLng = val._latlng.lng; }
    }
    
    var topLeft   = L.latLng( minLat, minLng ).toBounds(zonSize - 5);
    var bounds    = L.latLng( topLeft.getSouthEast() ).toBounds(zonSize + zonAdjust);
    var OriginTop = bounds.getNorthWest();
    //var OriginBot = bounds.getSouthEast();

    var recStart = bounds.getNorthEast();//{ lat: minLat, lng: minLng };
    var temp = [];
    var polyEmprise;

    while ( recStart.lat >= maxLat ) {
        temp = [ bounds.getNorthWest(), bounds.getNorthEast(), bounds.getSouthEast(), bounds.getSouthWest() ];
        polyEmprise = L.polygon(temp, {
            color: '#3388ff',
            opacity: 0.5,
            fillColor: 0,
            fillOpacity: 0.1  
        });
        this.layerEmprise.addLayer(polyEmprise);

        recStart= bounds.getNorthEast();
        if( recStart.lng > maxLng ){
            recStart= bounds.getSouthEast();
            recStart.lng = OriginTop.lng; //minLng;
        }
        topLeft = L.latLng( recStart.lat, recStart.lng ).toBounds(zonSize + zonAdjust);
        bounds  = L.latLng( topLeft.getSouthEast() ).toBounds(zonSize + zonAdjust);            
    }

    //this.Map.addLayer(this.layerEmprise);

    // -----------------------------------
    var polyLatLng= [];
    var polyTree;
    var treeBounds, iTreeBounds, sTreeBounds;
    var isintersect = false;
    var extBouds = [];
    //var tskIdTab = [];
    // Génératrion des emprises par arbres et multi-arbre
    this.layerEmprise.eachLayer( (zone) => {
        zone._tskIdTab = [];
        for (km = 0; (val = selMark[km]) !== undefined; km++) {
            if( val._iGoup == false && zone._bounds.contains( val._latlng ) ){
                isintersect= false;
                extBouds = [];
                treeBounds = L.latLng( val._latlng ).toBounds( 10 );
                zone._tskIdTab.push( { tsk_id: selMark[km]._tsk_id, pit_id: selMark[km]._pit_id, leaflet_id: selMark[km]._leaflet_id });
                // Recherche toute intersection pour ce point (avec distance m)
                var im, iVal;
                //var iContinue = 0;
                var iGroup = []; // point en stand by
                var iSearch= km; // index du point de départ
                var nbMark = selMark.length - 1; // dernier index
                for (im = 0; (iVal = selMark[im]) !== undefined; im++) {
                    if( zone._bounds.contains( iVal._latlng ) && selMark[im]._fid !== selMark[iSearch]._fid ){
                        iTreeBounds = L.latLng( selMark[im]._latlng ).toBounds( 10 );
                        sTreeBounds = L.latLng( selMark[iSearch]._latlng ).toBounds( 10 );
                        selMark[iSearch]._iGoup  = true; // reference est dans un group
                        if( selMark[im]._iGoup == false && sTreeBounds.intersects(iTreeBounds) ){
                            selMark[im]._iGoup  = true;
                            iGroup.push( im ); // intersec a verifier plus tard

                            if( isintersect == false ){ // Premier test
                                isintersect = true;
                                // Début polygon group
                                extBouds.push(sTreeBounds);
                            }
                            extBouds.push(iTreeBounds);
                            zone._tskIdTab.push( { tsk_id: selMark[im]._tsk_id, pit_id: selMark[im]._pit_id, leaflet_id: selMark[im]._leaflet_id });
                        }
                    }
                    if( nbMark == im && iGroup[0] !== undefined ){
                        iSearch = iGroup[0];
                        iGroup  = iGroup.slice(1);
                        im = -1;
                    }
                }
                // Si l'arbre est tout seul
                if( !isintersect ){
                    polyLatLng = [ treeBounds.getNorthWest(), treeBounds.getNorthEast(), treeBounds.getSouthEast(), treeBounds.getSouthWest() ];
                    polyTree   = L.polygon(polyLatLng, { color: 'black', opacity: 0.8, fillOpacity: 0.2 }); //fillColor: 0,
                    polyTree._zoneId  = zone._leaflet_id;
                    //polyTree._dateCal = selMark[km]._dateCal;
                    //polyTree._address = selMark[km]._address;
                    polyTree._taskObj = selMark[km]._taskObj
                    this.layerTreeEmprise.addLayer(polyTree);
                    polyLatLng = [];
                } else {
                    var rm, rVal;
                    treeBounds = extBouds[0];
                    for (rm = 1; (rVal = extBouds[rm]) !== undefined; rm++) {
                        treeBounds.extend(rVal);
                    }
                    polyLatLng = [ treeBounds.getNorthWest(), treeBounds.getNorthEast(), treeBounds.getSouthEast(), treeBounds.getSouthWest() ];
                    polyTree   = L.polygon(polyLatLng, { color: 'green', opacity: 0.8, fillOpacity: 0.2 }); //fillColor: 0,
                    polyTree._zoneId = zone._leaflet_id;
                    //polyTree._dateCal = selMark[km]._dateCal;
                    //polyTree._address = selMark[km]._address;
                    polyTree._taskObj = selMark[km]._taskObj
                    this.layerTreeEmprise.addLayer(polyTree);
                    polyLatLng = [];
                }
            }
        }
    });

    return m;
  }
  eraseEmprise(){
    this.clusterTask.eachLayer( (marker) => {
        marker.selected = false
        marker.setIcon( this.buildIcon( { type: 'grey-dict' } ) )
    })
    this.layerEmprise.eachLayer( (layer) => { this.layerEmprise.removeLayer(layer);});
    this.layerTreeEmprise.eachLayer( (layer) => { this.layerTreeEmprise.removeLayer(layer);});
  }

  sendSogecomDict(){
    return new Promise( (resolve) => {
      let geoEmp  = {};
      let geoJson = {};
      //let zoneTskId= {};
      let tree = []
      this.layerEmprise.eachLayer( (zone) => {
        //zoneTskId[zone._leaflet_id] = zone._tskIdTab;
        geoJson[zone._leaflet_id] = L.featureGroup();
        geoEmp[ zone._leaflet_id ] = { geom: {type: "MultiPolygon", coordinates: [] } };
        geoEmp[ zone._leaflet_id ].geom.coordinates[0] = [];
        geoEmp[ zone._leaflet_id ].geom.coordinates[0][0] = [];
        let nbTree = 0;
        let i      = 0;
        let dateTx = '';
        let address= '';
        let addlatlng =  '';
        let treeIds = '';
        this.layerTreeEmprise.eachLayer( (treeEmp) => {
          if( treeEmp._zoneId == zone._leaflet_id ){
            if(!zone._taskObj){
              zone._taskObj = treeEmp._taskObj
            }
            geoJson[zone._leaflet_id].addLayer(treeEmp);
            var em, eVal
            geoEmp[ zone._leaflet_id ].geom.coordinates[i]    = [];
            geoEmp[ zone._leaflet_id ].geom.coordinates[i][0] = [];
            for (em = 0; (eVal = treeEmp._latlngs[0][em]) !== undefined; em++) {
                geoEmp[ zone._leaflet_id ].geom.coordinates[i][0].push( [ eVal.lng, eVal.lat ] );
            }
            // Ajout du premier point (connexion ?...)
            geoEmp[ zone._leaflet_id ].geom.coordinates[i][0].push( [ treeEmp._latlngs[0][0].lng, treeEmp._latlngs[0][0].lat ] );
            nbTree += 1;
            i      += 1;
            treeIds += (nbTree == 1) ? treeEmp._taskObj.tsk_id : ',' + treeEmp._taskObj.tsk_id

            treeEmp._taskObj.tre_address = (!treeEmp._taskObj.tre_address) ? '' : treeEmp._taskObj.tre_address

            if( treeEmp._taskObj && treeEmp._taskObj.tre_address.length > address.length ){
                address = treeEmp._taskObj.tre_address;
                addlatlng = 'lon=' + treeEmp._bounds._northEast.lng + '&lat=' + treeEmp._bounds._northEast.lat;
            }
            if( treeEmp._taskObj && treeEmp._taskObj.tsk_date_plan && treeEmp._taskObj.tsk_date_plan.length == 10 && (treeEmp._taskObj.tsk_date_plan < dateTx || dateTx.length == 0) ){
              dateTx = treeEmp._taskObj.tsk_date_plan;
            }
          }
        })
        // prepare data
        if( nbTree > 0 ){
          zone._taskObj.addlatlng = addlatlng
          zone._taskObj.tsk_nbre = nbTree
          zone._taskObj.tsk_ids = treeIds
          zone._taskObj.viewcoor    = geoJson[zone._leaflet_id].getBounds().getCenter();
          zone._taskObj.dataCenter  = [ zone._taskObj.viewcoor.lat, zone._taskObj.viewcoor.lng ];
          zone._taskObj.dataJson = JSON.stringify( geoEmp[zone._leaflet_id] )
          zone._taskObj.dataGeom = JSON.stringify( geoJson[zone._leaflet_id].toGeoJSON() )
          zone._taskObj.address  = zone._taskObj.tre_loc_no + ' ' + zone._taskObj.tre_address
          tree.push(zone._taskObj)
        }
      })
      
      resolve(tree)
    })
  }

}

export default LfMap;