import Storages  from 'js-storage'

const APP_KEY   = require('../../package.json').appKey
const TOKEN_KEY = APP_KEY + '_access_token'
const REFRESH_TOKEN_KEY = APP_KEY + '_refresh_token'

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
**/
const TokenService = {
    getToken() {
        return Storages.localStorage.get(TOKEN_KEY)
    },

    saveToken(accessToken) {
        Storages.localStorage.set(TOKEN_KEY, accessToken)
    },

    removeToken() {
        Storages.localStorage.remove(TOKEN_KEY)
    },

    getRefreshToken() {
        return Storages.localStorage.get(REFRESH_TOKEN_KEY)
    },

    saveRefreshToken(refreshToken) {
        Storages.localStorage.set(REFRESH_TOKEN_KEY, refreshToken)
    },

    removeRefreshToken() {
        Storages.localStorage.remove(REFRESH_TOKEN_KEY)
    }

}

export { TokenService }