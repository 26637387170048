<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass">
            <v-icon large :color="contextStyle.titleColor" :class="contextStyle.titleIcon">{{ contextStyle.icon }}</v-icon>
            <span>{{ contextStyle.title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="success" outlined class="white--text" disabled><v-icon left dark>mdi-file-excel</v-icon></v-btn>
            </template>
            <span>Telecharger xlsx</span>
        </v-tooltip>
    </v-toolbar>
    <v-card class="ma-4 rounded-lg">
    <v-data-table v-bind:headers="headers" :items="items" item-key="tre_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" 
        show-select :single-select="singleSelect" v-model="selected">
        <template v-slot:top>
            <v-row>
                <v-col cols="6" md="6">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
            </v-row>
        </template>
        <template v-slot:[`item.tre_sta_id`]="{ item }">
            <v-chip small outlined label :color="getCacheState('GEN', item.tre_sta_id).sta_color">
                {{ getCacheState('GEN', item.tre_sta_id).sta_label }}
            </v-chip>
        </template>
        <template v-slot:[`item.tre_id`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/trees/sheet/' + item.tre_id + ''" disabled>
                <v-icon left class="mr-2">mdi-pencil</v-icon>Ouvrir
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'tasks',
    props: ['contextStyle'],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'IdBase',         align: 'left', value: 'tre_ext_id'},
            { text: 'Etat',           align: 'left', value: 'tre_sta_id'},
            { text: 'DICT',           align: 'left', value: 'tre_dict'},
            { text: 'Arrondissement', align: 'left', value: 'tre_district'},
            { text: 'N° voie',        align: 'left', value: 'tre_loc_no'},
            { text: 'Adresse',        align: 'left', value: 'tre_address'},
            { text: 'Modification', align: 'right',value: 'tre_id' },
            ],
            singleSelect: false,
            selected: [],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false,
            cacheTab: this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('trees') || ''
        this.getItems()
    },
    methods: {
        getItems(){
            this.$http.get( '/trees/' ).then( (response) => {
                this.items = response.data.data
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('trees', this.search)
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        
    }
}
</script>