const dayAbbreviation = ['D', 'L', 'M', 'M', 'J', 'V', 'S']
const dayList   = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
const monthList = ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jui', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec']
const monthLongList = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre']

export default {
    formatDisplay (date) {
        return `${dayList[date.getDay()]}, ${monthList[date.getMonth()]} ${date.getDate()}`
    },
    formatMonth (date) {
        return `${monthLongList[date.getMonth()]} ${date.getFullYear()}`
    },
    getWeekDayArray (firstDayOfWeek) {
        let beforeArray = []
        let afterArray = []
        for (let i = 0; i < dayAbbreviation.length; i++) {
        if (i < firstDayOfWeek) {
            afterArray.push(dayAbbreviation[i])
        } else {
            beforeArray.push(dayAbbreviation[i])
        }
        }
        return beforeArray.concat(afterArray)
    },
    formatLocale(datef, format){
        format = format || 'fr'
        datef  = datef || '0000-00-00'
        let dateRet = ''
        let dateTab = [ '', '', '' ]
        //let datePre = datef.split(' ')
        //datef = datePre[0]
        datef = datef.substring( 0, 10 )
        if( datef.indexOf('-') == 4 ){
            dateTab = datef.split('-')
        } else if ( datef.indexOf('-') == 4 ) {
            dateTab = datef.split('/')
        }
        // Formatage
        if( format == 'fr' ){
            dateRet = dateTab[2] + '/' + dateTab[1] + '/' + dateTab[0]
        } else {
            dateRet = dateTab[2] + '/' + dateTab[1] + '/' + dateTab[0]
        }
        return dateRet
    },
    toLocale( date, option ){
        //option      = option || { year: 'numeric', month: 'long', day: 'numeric' } 
        if( date !== null && date !== undefined && date.length > 2 ){
            var objDate = new Date(date)
            return objDate.toLocaleDateString('fr-FR', option)
        } else {
            return ''
        }
    },
    getToday( locale ){
        locale = locale || 'fr'
        var today = new Date()
        var dd    = today.getDate()
        var mm    = today.getMonth()+1 //January is 0!
        var yyyy  = today.getFullYear()
        if(dd<10){ dd='0'+dd; }
        if(mm<10){ mm='0'+mm; }
        if( locale == 'fr' ){
            return dd + '/' + mm + '/' + yyyy
        } else {
            return yyyy + '-' + mm + '-' + dd
        }
    },
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
    
        return [year, month, day].join('-');
    }
}