<template>
<v-container fluid>
<v-row>
    <v-col cols="12" md="6">
        <v-card class="elevation-2" tile flat v-if="isAuthorized( 'jobs', 'read' )">
        <v-toolbar flat color="#f6f8f8" prominent height="50px">
            <v-toolbar-title>
                <v-icon>mdi-format-list-checks</v-icon>
                Liste des tâches en cours
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn icon><v-icon>mdi-restore</v-icon></v-btn> -->
        </v-toolbar>
        <v-divider></v-divider>
        <v-list class="pb-0" two-line>
            <template v-for="(item, index) in items">
                <v-list-item :key="index" class="pb-0">
                    <v-list-item-avatar>
                        <v-progress-circular v-if="!item.state" indeterminate color="primary"></v-progress-circular>
                        <v-chip v-else small color="green" ></v-chip>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ (item.state) ? item.message : item.id }} <span class="caption grey--text">({{ item.uuid }})</span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="caption font-italic">Traitement: -</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <span class="caption">{{ locDateFormat.getToday() }}</span>
                        <v-chip v-if="item.state == 'done'" small outlined label light color="green">Terminé</v-chip>
                        <v-chip v-else-if="item.state == 'error'" small outlined label light color="red">Erreur</v-chip>
                        <v-chip v-else small outlined label light color="orange">Traitement</v-chip>
                    </v-list-item-action>
                </v-list-item>
                <v-divider :key="'D-' + index"></v-divider>
            </template>
        </v-list>
        </v-card>
    </v-col>
</v-row>
</v-container>
</template>

<script>
import dateLocale  from '../services/dateLocale'
import { usrGetters }   from "../store/user.module"

export default {
    name: 'bckjobs',
    props: [],
    data: function () {
        return {
            loadingData: false,
            items: [],
            locDateFormat: dateLocale,
            isAuthorized: usrGetters.getScopeRight,
            checkJobs: null
        }
    },
    mounted: function (){
        this.getJobs()
        this.checkJobs = setInterval( () => {
            this.getJobs()
        }, 7000);
    },
    beforeDestroy: function () {
        clearInterval(this.checkJobs)
    },
    methods: {
        getJobs(){
            return new Promise( (resolve, reject) => {
                this.loadingData  = true
                //this.items        = []
                var searchCriteria = ''

                this.$http.get( '/jobs' + searchCriteria ).then( (response) => {
                    let elemObj = []
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        response.data.data.forEach(element => {
                            try {
                                elemObj.push( JSON.parse( element ) )
                            } catch (error) {
                                this.$root.$toast({ color: 'error', text: error })
                            }
                        })
                        this.items = elemObj
                    }
                    this.loadingData = false
                    resolve('resolved')
                }).catch( (error) => { 
                    this.loadingData = false
                    reject(error)
                })
            })
        },
    },
    components: {

    }
}
</script>

<style lang="css">

</style>