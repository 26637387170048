<template>
<div>
    <v-menu :ref="fieldName" v-model="dateMenu[fieldName]"
        :return-value.sync="objectForm[fieldName]"
        :close-on-content-click="true" :nudge-right="40" transition="scale-transition" 
        offset-y min-width="290px" style="z-index:1500">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field :style="(width) ? 'width:' + width + ';' : ''"
                clear-icon="mdi-close-circle" clearable @click:clear="clearDate"
                v-bind="attrs" :value="(objectForm[fieldName]) ? $date(objectForm[fieldName]).format('DD/MM/YYYY') : ''"
                :label="label" :prepend-icon="params.preprendIcon" readonly v-on="on" 
                :dense="params.dense" :outlined="params.outlined">
                <template v-slot:prepend v-if="params.preprendIcon"><v-icon :color="(objectForm[fieldName]) ? 'green' : 'grey'">{{params.preprendIcon}}</v-icon></template>
            </v-text-field>
        </template>
        <v-date-picker locale="fr-fr" no-title scrollable 
            @input="$refs[fieldName].save( objectForm[fieldName] )"
            v-model="objectForm[fieldName]" first-day-of-week="1" @change="$emit('change')">
            <v-btn color="red" @click="objectForm[fieldName] = null; $refs[fieldName].save( objectForm[fieldName] )">
                <v-icon>mdi-eraser</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="todayEn()">Aujourd'hui</v-btn>
            <!-- <v-btn color="primary" @click="dateMenu[fieldName] = false">Annuler</v-btn>
            <v-btn color="primary" @click="$refs[fieldName].save( objectForm[fieldName] )">OK</v-btn> -->
        </v-date-picker>
    </v-menu>
</div>
</template>

<script>

export default {
    name: 'date_picker',
    props: ['objectForm', 'fieldName', 'label', 'disabled', 'customStyle', 'preicon', 'width', 'options'], 
    data: function () {
        return {
            loading     : { getVersion: false, getRessource: false },
            ischange    : false,
            //preprendIcon  : 'mdi-account',
            //params        : '',
            //isDisabled    : this.disabled || false,
            //outlined      : false,
            //dense         : false,
            //style         : (this.customStyle == undefined) ? 'z-index:2000;' : this.customStyle,
            dateMenu      : { [this.fieldName]: false },
            params: { dense:false, outlined:false, preprendIcon:'mdi-eraser' }
        }
    },
    beforeMount: function(){
        if( this.options ){
            this.params.dense        = this.options.dense || false
            this.params.outlined     = this.options.outlined || false
            this.params.preprendIcon = (typeof this.options.preprendIcon !== 'undefined') ? this.options.preprendIcon : 'mdi-calendar-today'
        }
    },
    mounted:function (){
        this.prependIcon = (typeof this.preicon !== 'undefined') ? this.preicon : 'mdi-calendar-today'
        //this.preprendIcon = (this.fields.preprendIcon == undefined) ? undefined : this.fields.preprendIcon
        //this.outlined     = (this.fields.outlined === true) ? true : false
        //this.dense        = (this.fields.dense === true) ? true : false
    },
    methods: {
        clearDate(){
            this.objectForm[this.fieldName] = null
            this.$emit('change')
        },
        todayEn(){
            let today  = new Date().toJSON().slice(0, 10)
            //today = new Date().toJSON().slice(0, 19).replace('T', ' ')
            this.objectForm[this.fieldName] = today
            this.$refs[this.fieldName].save( this.objectForm[this.fieldName] )
            this.$emit('change')
        }
    }
}
</script>