<template>
<div style="height: 100%; position: relative; overflow:hidden;">
    <div id="map-container" style="height: 100%;">
    </div>
    <v-menu bottom left style="z-index:1050;">
        <template v-slot:activator="{ on }">
            <v-btn 
                fixed dark fab bottom right color="orange" style="opacity: 0.80; top:80px; z-index:1050;" 
                elevation-1 v-on="on" title="Changer le fond de carte">
                  <v-icon>mdi-layers</v-icon>
            </v-btn>
        </template>
            <v-list dense class="rounded-lg">
                <template v-for="(item, pindex) in tileLayer">
                <v-list-item :key="pindex" @click="changeTileLayer(pindex)">
                  <v-list-item-action>
                      <img :src="item.thumbnail" width="65" class="rounded-lg" :style="(activeTile == pindex) ? 'border: 3px solid orange;' : ''" />
                    </v-list-item-action>
                  <v-list-item-title>{{item.text}}</v-list-item-title>
                </v-list-item>
                </template>
            </v-list>
    </v-menu>
    <v-btn v-show="(canDisplayTree || addDisplayTree)"
        :loading="loading.getAllTree"
        fixed dark fab bottom right :color="(addDisplayTree) ? 'green' : 'primary'" style="opacity: 0.80; top:160px; z-index:1040;" 
        elevation-1 @click="displayAllTree(!addDisplayTree)" title="Afficher tous les arbres">
        <v-icon>mdi-tree</v-icon>
    </v-btn>
    <v-btn 
        fixed dark fab bottom right :color="(addDictState) ? 'red' : 'red'" style="opacity: 0.80; right: 100px; bottom:50px; z-index:1040;" 
        elevation-1 @click="eraseEmprise()" title="Effacer">
        <v-icon>mdi-eraser</v-icon>
    </v-btn>
    <v-btn 
        fixed dark fab bottom right :color="(addDictState) ? 'green' : 'blue'" style="opacity: 0.80; bottom:50px; z-index:1040;" 
        elevation-1 @click="(!addDictState) ? autoDrawEmprise() : openDictList()" title="Créer les brouillons">
        <v-icon>{{ (!addDictState) ? 'mdi-eye-check-outline' : 'mdi-send-outline'}}</v-icon>
    </v-btn>
    <div style="min-width:25%; position:absolute; left:10px; top:20px; z-index: 899;">
        <v-autocomplete
        v-model="searchAddress"
        label="Rechercher"
        :items="addressTab"
        item-value="id" item-text="label"
        :search-input.sync="search"
        @input="panTo"
        append-icon="mdi-magnify" filled outlined dense clearable class="rounded-xl" no-filter
        background-color="white" style="opacity: 0.7;">
        </v-autocomplete>
    </div>
    <v-card elevation="2" class="rounded-lg" style="position:absolute; left:10px; top:80px; z-index: 890;">
        <div>
        <v-btn class="ma-1" small outlined text @click="drawObject('Rectangle')" title="Selection rectangle">
            <v-icon>mdi-vector-square</v-icon>
        </v-btn>
        </div>
        <div>
        <v-btn class="ma-1" small outlined text @click="drawObject('Polygon')" title="Selection polygone">
            <v-icon>mdi-vector-polyline</v-icon>
        </v-btn>
        </div>
    </v-card>
    <!-- ------------Alerte view ----------------- -->
    <v-dialog v-model="modalEvent" max-width="900" style="z-index:2066;">
        <v-card min-width="350px" flat class="rounded-lg">
        <v-toolbar color="primary" dark flat height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>{{selectionEvent.title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalEvent', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="elevation-0 pa-0">
            <edit-event 
                v-if="modalEvent" :switchModal="switchModal"
                :xxxId="selectionEvent.xxxId" :eveId="selectionEvent.eveId" 
                :eveTable="selectionEvent.eveTable" :eveType="selectionEvent.eveType" 
                :title="selectionEvent.title" :cooridnates="selectionEvent.cooridnates">
            </edit-event>
        </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Selection d'arbres rapport -->
    <v-dialog v-if="selectionDialog" v-model="selectionDialog" max-width="800px">
    <v-card>
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-toolbar-title>Arbres selectionnées : {{ selectionItems.length }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="selectionDialog = false; eraseEmprise()"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pt-2 pl-0 pr-0">
            <v-data-table v-bind:headers="treeHeaders" :items="selectionItems" item-key="tsk_id" :search="search"
                :disable-pagination="true" hide-default-footer height="500px" fixed-header>
                <template v-slot:[`item.tre_address`]="{ item }">
                    <v-text-field :dense="fieldStyle.dense" :value="item.address"></v-text-field>
                </template>
                <template v-slot:[`item.tsk_typ_id`]="{ item }">
                    <v-menu :ref="'tsk_date_plan' + item.tsk_id" v-model="dateMenu['tsk_date_plan' + item.tsk_id]"
                        :return-value.sync="item.tsk_date_plan"
                        :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                        offset-y min-width="290px" style="z-index:1500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip v-if="!item.tsk_date_plan" v-on="on" v-bind="attrs" small outlined label :color="getCacheType('TSK', item.tsk_typ_id).typ_color">{{ getCacheType('TSK', item.tsk_typ_id).typ_name }}</v-chip>
                            <v-chip v-else small outlined color="green" v-on="on" v-bind="attrs">
                                <span v-if="item.tsk_date_plan">{{ locDateFormat.toLocale(item.tsk_date_plan) }}</span>
                                <v-icon v-else color="green">mdi-calendar-edit</v-icon>
                            </v-chip>
                        </template>
                        <v-date-picker locale="fr-fr" no-title scrollable v-model="item.tsk_date_plan">
                            <v-btn color="red" @click="item.tsk_date_plan = null"><v-icon>mdi-eraser</v-icon></v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="dateMenu['tsk_date_plan' + item.tsk_id] = false">Annuler</v-btn>
                            <v-btn color="primary" @click="$refs['tsk_date_plan' + item.tsk_id].save( item.tsk_date_plan ); reverseAddress(item);">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </template>
                <template v-slot:[`item.tsk_date_plan`]="{ item }">
                    <v-btn color="green" @click="sendDictDraft(item);" :disabled="(!item.tsk_date_plan || item.tsk_dict )">
                        <v-icon>mdi-send</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card-text>
        <v-card-actions class="grey lighten-3">
            <v-spacer></v-spacer>
            <v-btn rounded text color="#595959" dark @click.native="selectionDialog = false; eraseEmprise()">
                <v-icon left dark>mdi-close</v-icon>Fermer
            </v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
</div>
</template>

<script>
import dateLocale from '../services/dateLocale';
import LfMap        from '../plugins/map';
import { cacheGetters, cacheData } from "../store/cache.module"
import { bus } from '@/plugins/bus'
import { tskObj }       from '../mixins/tsk_obj.js'
import editEvent  from '../components/Edit_event.vue'
var mapthis = { map: {} }
import { taskUpdateService } from '@/rxjsServices';
import { filterUpdateService } from '@/rxjsServices';

export default {
    name: 'maptool',
    props: ['filterView'],
    mixins: [ tskObj ],
    data: function () {
        return {
            saveProgress: false,
            locDateFormat: dateLocale,
            fieldStyle: { outlined: true, dense: true },
            map: null,
            loading: { getVersion: false, getMap: false, filters: [], getAllTree: false },
            alertDialog: false,
            madeDialog: false, colorDialog: false,
            attribDialog: false,
            botNav: { height: 55 },
            layerMenu: false,
            filterMenu: false,
            versionMenu: false,
            blockMenu: false, blockMenuGen: false,
            popup: false, 
            dealIcon: false,
            dealTab: [],
            deal: {},
            drawEdit: false, dialogObj:false, dialogLayer: false, modalEvent: false,
            selectionDialog: false,
            selectionItems: [],
            selectionEvent: { xxxId: [], eveId:0, eveTable: "cad", eveType: "ALT", title:"Alertes", cooridnates:null },
            dxfObject: {},
            selectList : this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
            state      : this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
            objectList : {},
            lraObj: { lra_tem_id:1, lra_obt_id:1, lra_source_id: '', color: '#000000' },
            layerList: [], allLayerList: [], objLayerList: {},
            dxfLayer: [], dxfBlocks: {}, genBlocks: {}, dxfVersion: [],
            layerLetter: [],
            stateLetter: [],
            letterDisplay: 0,
            drawSaved: true,
            projection: 'EPSG3857',
            toolBar: 1,
            toolMode: 0,
            drawState: { editMode: false, dragMode: false, rotation:0 },
            alertObj : {
                alt_id : 0, alt_lat : 0, alt_lng : 0
            },
            loadAlert: false,
            loadTask: false,
            verTab: {},
            lraColor: { lra_id:0, lra_color:'', lra_source_id:'' },
            tabVersion: {},
            layerAction: 'copy',
            cardVersionType: '',
            filters: [],
            pro_id: 0, //this.user.usr_pro_id,
            modalFilter: false,
            filterInfo: {},
            layerSource: true,
            activeTile: 'ign',
            tileLayer: {
                ign : { text: 'Fond de plan IGN',   thumbnail: require('../assets/plan_ign.jpg'),   link: 'https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/osmtiles/webmercator/{z}/{x}/{y}.png', color: 'green', icon: 'mdi-check'},
                osm : { text: 'Fond de plan OSM',   thumbnail: require('../assets/plan_osm.jpg'),   link: 'https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/arcgistiles/webmercator/{z}/{x}/{y}.png', color: 'green', icon: 'mdi-check'},
                bph : { text: 'Fond de plan photo', thumbnail: require('../assets/plan_photo.jpg'), link: 'https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/igntiles/webmercator/{z}/{x}/{y}.png', color: 'green', icon: 'mdi-check'},
                bwt : { text: 'Sans fond',          thumbnail: require('../assets/plan_sans.jpg'),  link: '', color: 'green', icon: 'mdi-check'},
            },
            addSelectionState: false,
            addMarkerState: false,
            addTreeState: false,
            addDictState: false,
            addDisplayTree: false,
            searchAddress: null,
            addressTab: [],
            search: null,
            searchWord: '',
            timeOutHandle: 'off',
            filter_table: {}, //this.user.filter_table_id //this.$auth.userPrefuser.filter_table_id,
            canDisplayTree: false,
            districtState: false,
            dateMenu: { tsk_date_plan_set_all: null },
            tsk_date_plan_set_all: null,
            treePagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            treeHeaders: [
                { text: 'Id',      align: 'left',  value: 'tsk_id'},
                { text: 'Nbre',    align: 'left',  value: 'tsk_nbre'},
                { text: 'Date',    align: 'left',  value: 'tsk_typ_id'},
                { text: 'Adresse', align: 'left',  value: 'tre_address'},
                { text: '-',       align: 'right', value: 'tsk_date_plan'},
            ],
        }
    },
    watch: {
        search (val) {
            this.searchWord = val
            if( this.timeOutHandle == 'off' && val ){
                this.timeOutHandle = 'on'
                clearTimeout(this.searchTempo)
                this.searchTempo = setTimeout( () => {
                    if( isNaN(this.searchWord) ){
                        this.loadAddress(this.searchWord)
                    } else {
                        this.searchTreeByExtid(this.searchWord)
                    }
                    
                    this.timeOutHandle = 'off'
                }, 1500)
            }
        },
    },
    computed: {
        user(){
            return cacheData.user
        },
    },
    created(){
        this.subTask = taskUpdateService.getTask().subscribe(message => {
            if (message) {
                message.color = this.getCacheType('TSK', message.tsk_typ_id).typ_color
                message.color = (message.tsk_sta_id == 'GEN03') ? this.getCacheState('GEN', message.tsk_sta_id).sta_color : message.color
                mapthis.map.updateTask( message )
            }
        })
        this.subFilter = filterUpdateService.getFilter().subscribe(message => {
            if (message) {
                this.displayDict()
            }
        })
    }, 
    beforeMount() {
        this.filter_table = this.user.filter_table_id
    },
    mounted() {
        mapthis.map = new LfMap('map-container')
        bus.$on('task:select', (obj) => this.showSelectionCard(obj))
        bus.$on('tree:get',    (obj) => this.zoomToGetTree(obj))

        if( this.$route.params.latlng !== undefined && this.$route.params.latlng.length > 0 ){
            mapthis.map.mapCenter = this.$route.params.latlng.split(',')
        }

        this.initMap()        
    },
    methods:{
        async initMap(){
            await this.displayMap()
            await this.displayDict()
        },
        displayMap() {
            return new Promise( (resolve) => {
                if( cacheData.pro_projection == 'CRS' ){
                    this.projection = 'Simple'
                }
                mapthis.map.display( [47.47479783478821, 1.0530929565429689], 5, this.projection )
                mapthis.map.modeDict = true
                resolve('resolved')
            })
        },
        displayDict(){
            return new Promise( (resolve, reject) => {
                mapthis.map.removeAllTask()
                this.loadTask = true
                if( this.loadTask ){
                    let url    = '?per_page=false&extended=withtree,withactivealert&tsk_dict=0'
                    //let option = cacheGetters.getFilterUrl()
                    //url    += ( option.length ) ? '&' + option : ''
                    this.getTaskIndex(1, false, url).then( () => {
                        var kp, vp
                        for (kp = 0; (vp = this.listTask[kp]) !== undefined; kp++) {
                            if( vp.tre_lat && vp.tre_lng ){
                                vp.color = this.getCacheType('TSK', vp.tsk_typ_id).typ_color
                                vp.color = (vp.tsk_sta_id == 'GEN03') ? this.getCacheState('GEN', vp.tsk_sta_id).sta_color : vp.color
                                mapthis.map.addTask( vp, 'dict' )
                            }
                        }
                        mapthis.map.fitBoundLayerGroup()
                        resolve('resolved')
                    }).catch( (error) => { 
                        reject(error)
                    })
                } else {
                    mapthis.map.removeAllAlert()
                    resolve('resolved')
                }
            })
        },
        zoomToGetTree(zoom){
            if( zoom >= 17 ){
                this.canDisplayTree = true
            } else {
                this.canDisplayTree = false
            }
        },
        displayTree(bound){
            return new Promise( (resolve, reject) => {
                mapthis.map.removeAllTree()
                this.loadTask = true
                let zonePram = '&min_tre_lat=' + bound._southWest.lat + '&min_tre_lng=' + bound._southWest.lng
                zonePram    += '&max_tre_lat=' + bound._northEast.lat + '&max_tre_lng=' + bound._northEast.lng
                if( this.loadTask ){
                    this.$http.get( '/trees/?per_page=false&extended=withoutinfo&min_tre_lat=1' + zonePram ).then( (response) => {
                        var kp, vp
                        for (kp = 0; (vp = response.data.data[kp]) !== undefined; kp++) {
                            if( vp.tre_lat && vp.tre_lng ){
                                vp.color = 'grey'
                                vp.tre_address = (!vp.tre_address) ? '' : vp.tre_address
                                mapthis.map.addTree( vp )
                            }
                        }
                        resolve('resolved')
                    }).catch( (error) => { 
                        reject(error)
                    })
                } else {
                    mapthis.map.removeAllAlert()
                    resolve('resolved')
                }
            })
        },
        async displayAllTree(state){
            this.loading.getAllTree = true
            let bound = mapthis.map.Map.getBounds()
            this.addDisplayTree = state
            if( this.addDisplayTree ){
                mapthis.map.removeAllTask()
                await this.displayTree(bound)
                this.loading.getAllTree = false
            } else {
                mapthis.map.removeAllTree
                await this.displayDict()
                this.loading.getAllTree = false
            }
        },
        closePopup(){
            this.dealIcon = false
            mapthis.map.clearDealMarker()
        },
        showSelectionCard(bound){
            this.tsk_date_plan_set_all = null
            let zonePram = '&min_tre_lat=' + bound._southWest.lat + '&min_tre_lng=' + bound._southWest.lng
            zonePram    += '&max_tre_lat=' + bound._northEast.lat + '&max_tre_lng=' + bound._northEast.lng
            let url    = '?per_page=false&extended=withtree,withactivealert&tsk_dict=0'
            //let option = cacheGetters.getFilterUrl()
            //url    += ( option.length ) ? '&' + option : ''
            url    += zonePram
            this.getTaskIndex(1, false, url).then( () => {
                this.listTask.forEach(element => {
                    this.dateMenu['tsk_date_plan' + element.tsk_id]
                    element['tre_loc_no']  = ( element['tre_loc_no'] === null ) ? '-' : element['tre_loc_no']
                    element['tre_address'] = ( element['tre_address'] === null ) ? '-' : element['tre_address']
                    element['tsk_vol'] = Math.round( parseFloat(element.tsk_base_lenght) * parseFloat(element.tsk_base_width) * parseFloat(element.tsk_base_depth) * 100 ) / 100
                });
                this.selectionItems  = this.listTask
                this.selectionDialog = true
            })
        },
        async computeAdress(){
            for (const item of this.selectionItems) {
                await this.reverseAddress(item)
            }
        },
        async saveSelectionCard(){
            this.saveProgress = true
            for (const element of this.listTask) {
                await this.checkTaskState(element)
                await this.putTaskStore( { tsk_sta_id: 'GEN01', tsk_id: element.tsk_id, tsk_typ_id: element.tsk_typ_id, tsk_date_plan: element.tsk_date_plan } )
                taskUpdateService.sendTask({ tsk_sta_id: 'GEN01', tsk_id: element.tsk_id, tsk_typ_id: element.tsk_typ_id, tsk_date_plan: element.tsk_date_plan });
            }
            setTimeout( () => {
                //this.displayDict()
                this.switchModal('selectionDialog')
                this.saveProgress = false
            }, 700)
        },
        autoDrawEmprise(){
            let nb = mapthis.map.mapAutoDrawPoly()
            if( nb ){
                this.addDictState = true
            } else {
                this.$root.$toast({ color: 'error', text: 'Aucune selection' })
                this.addDictState = false
            }
        },
        async eraseEmprise(){
            mapthis.map.eraseEmprise()
            await this.displayDict()
            this.addDictState = false
        },
        setAllPlan(){
            this.selectionItems.forEach(element => {
                element.tsk_date_plan = this.tsk_date_plan_set_all
            })
        },
        async openDictList(){
            this.selectionItems = await mapthis.map.sendSogecomDict()
            this.selectionDialog = true
            this.computeAdress()
        },
        sendDictDraft(item){
            let index = this.selectionItems.findIndex(x => x.tsk_id === item.tsk_id);
            this.selectionItems.splice(index, 1);
            this.$http.post( '/dicts/', item ).then( () => {
                item.tre_dict = 1
                this.$root.$toast({ color: 'success', text: 'Fiche envoyée !' })
            })
        },
        switchModal(name, mode){
            name    = name || 'dialogObj'
            mode    = mode || false
            //refresh = refresh || false
            this[name] = mode
            if( name == 'modalEvent' && !mode ){
                this.loadAlert = false
            }
        },
        changeTileLayer(id){
            this.activeTile = id
            mapthis.map.setTileLayer(this.tileLayer[id].link)
        },
        panTo(){
            if( this.searchAddress !== null && this.searchAddress !== undefined ){
                var coor = this.addressTab[ this.searchAddress ].item.geometry.coordinates
                mapthis.map.panTo( coor )
                mapthis.map.addCircleZone( coor )
            }
        },
        loadAddress (val) {
            if( val.length > 1 ){
                this.$http.customRequest({
                    headers: '',
                    baseURL: '',
                    method: 'get',
                    url: "https://api-adresse.data.gouv.fr/search/?q=" + val + "&limit=6"
                })
                .then( (response) => {
                    let data = []
                    let address, key
                    for (key = 0; (address = response.data.features[key]) !== undefined; key++) {
                        data[key] = { label: address.properties.label, value: address.properties.id, id:key, item: address }
                    }
                    this.addressTab = data
                })
            } else {
                return false
            }
        },
        reverseAddress(item){
            return new Promise( (resolve) => {
                this.$http.customRequest({
                        headers: '',
                        baseURL: '',
                        method: 'get',
                        url: "https://api-adresse.data.gouv.fr/reverse/?lon=" + item.tre_lng + '&lat=' + item.tre_lat
                    }).then( (response) => {
                        var addConcat = '';
                        addConcat    += ( response?.data?.features[0]?.properties.housenumber != undefined ) ? response.data.features[0].properties.housenumber + ', ' : ', ';
                        addConcat    += ( response?.data?.features[0]?.properties.street != undefined )      ? response.data.features[0].properties.street + ', ' : ', ';
                        addConcat    += ( response?.data?.features[0]?.properties.postcode != undefined )    ? response.data.features[0].properties.postcode + ', ' : ', ';
                        addConcat    += ( response?.data?.features[0]?.properties.district != undefined )    ? response.data.features[0].properties.district + ', ' : ', ';
                        addConcat    += ( response?.data?.features[0]?.properties.citycode != undefined )    ? response.data.features[0].properties.citycode + '' : '';
                        this.selectionItems.find((o, i) => {
                            if (o.tsk_id == item.tsk_id) {
                                this.selectionItems[i].address = addConcat
                            }
                        })
                        item.tre_address = addConcat
                        resolve('resolved')
                    }).catch( (error) => {
                        resolve(error)
                    })
            })
        },
        searchTreeByExtid(val){
            this.$http.get( encodeURI('/trees/?tre_ext_id=%' + val + '%') ).then( (response) => {
                let data = []
                let key  = 0
                response.data.data.forEach(element => {
                    data.push({ 
                        label: '[' + element.tre_ext_id + '] ' + element.tre_address, value: element.tre_id, id:key, item: {
                            geometry   : { coordinates: [ element.tre_lng, element.tre_lat ] },
                            properties : {},
                            type       : {},
                        }
                    })
                    key++
                })
                this.addressTab = data
            })
        },
        drawObject(tool) {
            mapthis.map.enablePmDraw(tool);
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates,
    },
    beforeDestroy() {
        bus.$emit('off')

        var layersState = {}
        for (var item in this.objLayerList) {
            layersState[item] = this.objLayerList[item].active
        }
        cacheData.filter_layer = layersState
        //this.$auth.savePref()
        // unsubscribe to ensure no memory leaks
        this.subTask.unsubscribe()
        this.subFilter.unsubscribe()
    },
    components: {
        //'object-edit': objectEdit,
        'edit-event'   : editEvent
    }
}
</script>

<style lang="scss">
@import "../../node_modules/leaflet/dist/leaflet.css";
@import '../assets/leaflet.pm.css';
@import "../../node_modules/leaflet.markercluster/dist/MarkerCluster.css";
@import "../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css";
@import '../assets/leaflet.css';

.dealInfo {
  position: absolute;
  top: 5px;
  right:5px;
  background-color: white;
  z-index: 500;
  max-width: 250px;
  max-width: 300px;
  max-height: 80%;
}
.leaflet-pane {
    z-index: 0;
}
.v-overlay--active{
    z-index: 1053 !important;
}
.v-dialog__content--active {
    z-index: 1055 !important;
}
.bottnavdraw {
    position: fixed;
}
.v-btn.active .v-icon {
    transform: rotate(-45deg);
}
</style>