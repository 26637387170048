import { scopeCredential } from "./scopeCredential"

const ChMain        = () => import(/* webpackChunkName: "build-glob" */ './layouts/Main.vue') 
const ChDasboard    = () => import(/* webpackChunkName: "build-glob" */ './pages/Dashboard.vue')
const ChMap         = () => import(/* webpackChunkName: "build-map" */ './pages/Map.vue')
const ChDocument    = () => import(/* webpackChunkName: "build-glob" */ './pages/Document.vue')
const ChTask        = () => import(/* webpackChunkName: "build-glob" */ './pages/Task.vue')
const ChPlanning    = () => import(/* webpackChunkName: "build-glob" */ './pages/Planning.vue')
const ChSlider      = () => import(/* webpackChunkName: "build-glob" */ './components/Slider_task.vue')
const ChEditCard    = () => import(/* webpackChunkName: "build-glob" */ './components/Edit_task.vue')

export default {
menu : [
    { 'href': '/dashboard',   'title': 'Tableau de bord', 'icon': 'mdi-view-dashboard', 'role': ['ADMIN_USER', 'SUPER_USER', 'EXTERN_USER'] },
    { 'href': '/tasks',       'title': 'Liste fosse', 'icon': 'mdi-traffic-cone', 'role': ['ADMIN_USER', 'SUPER_USER', 'EXTERN_USER'] },
    { 'href': '/planning',    'title': 'Calendrier', 'icon': 'mdi-calendar-check', 'role': ['ADMIN_USER', 'SUPER_USER', 'EXTERN_USER'] },
    { 'href': '/map',         'title': 'Carte', 'icon': 'mdi-map-outline', 'role': ['ADMIN_USER', 'SUPER_USER', 'EXTERN_USER'] },
    { 'href': '/mapdict',     'title': 'Carte DICT', 'icon': 'mdi-map-check-outline', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER' ] },
    { 'href': '/alerts',      'title': 'Alertes', 'icon': 'mdi-alert', 'role': ['ADMIN_USER', 'SUPER_USER'] },
    //{ 'href': '/documents',     'title': 'Documents', 'icon': 'mdi-briefcase', 'role': ['ADMIN_USER', 'SUPER_USER', 'EXTERN_USER'] },
    { divider: true },
    { 'header': 'Importer', 'role': ['ADMIN_USER', 'SUPER_USER', 'EXTERN_USER'] },
    { 'href': '/importtask', 'title': 'Import', 'icon': 'mdi-microsoft-excel', 'role': ['ADMIN_USER', 'SUPER_USER', 'EXTERN_USER'] },
    //{ 'header': 'Reporting', 'role': ['ADMIN_USER', 'SUPER_USER'] },
    //{ 'href': '/reports', 'title': 'Export', 'icon': 'mdi-file-chart', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'] },
    { divider: true },
    { 'header': 'Paramètres',      'title': 'Paramètres', 'icon': 'mdi-tools', 'role': ['ADMIN_USER', 'SUPER_USER', 'PARTE_USER'], items: [
        { 'href': '/vehicles',     'title': 'Véhicule', 'icon': 'mdi-truck', 'role': ['ADMIN_USER', 'SUPER_USER'] },
        { 'href': '/sessions',     'title': 'Session', 'icon': 'mdi-format-list-checks', 'role': ['ADMIN_USER', 'SUPER_USER'] },
        { 'href': '/states',       'title': 'Gestion états', 'icon': 'mdi-tag-multiple', 'role': ['ADMIN_USER'] },
        { 'href': '/types',        'title': 'Gestion des types', 'icon': 'mdi-format-list-bulleted-type', 'role': ['ADMIN_USER'] },
        { 'href': '/contacts',     'title': 'Gestion des contacts', 'icon': 'mdi-account-box-multiple', 'role': scopeCredential.contacts.read },
        { 'href': '/trees',       'title': 'Gestion des arbres', 'icon': 'mdi-tree-outline', 'role': ['ADMIN_USER'] },
    ] },
//    { divider: true },
//    { 'header': 'DICT',      'title': 'DICT', 'icon': 'mdi-sign-caution', 'role': ['ADMIN_USER', 'SUPER_USER', 'PARTE_USER'], items: [
//        { 'href': '/updatedict',  'title': 'MAJ DICT', 'icon': 'mdi-format-list-checks', 'role': ['ADMIN_USER', 'SUPER_USER', 'EXTERN_USER'] },
//        { 'href': '/dict',        'title': 'Gestion DICT', 'icon': 'mdi-format-list-checks', 'role': ['ADMIN_USER', 'SUPER_USER', 'EXTERN_USER'] },
//        { 'href': '/mapdict',     'title': 'Carte DICT', 'icon': 'mdi-format-list-checks', 'role': ['ADMIN_USER', 'SUPER_USER', 'EXTERN_USER'] },
//    ] },
    { divider: true },
    { 'header': 'Systeme', 'title': 'Paramètres', 'icon': 'mdi-cogs', 'role': ['ADMIN_USER', 'SUPER_USER' ], items: [
        { 'href': '/users', 'title': 'Utilisateurs', 'icon': 'mdi-account', 'role': ['ADMIN_USER', 'SUPER_USER' ] },
        { 'href': '/importdata',  'title': 'Import / synchro',  'icon': 'mdi-file-upload', 'role': ['ADMIN_USER', 'SUPER_USER'] },
        { 'href': '/jobs',  'title': 'Taches de fond', 'icon': 'mdi-progress-wrench', 'role': ['ADMIN_USER', 'SUPER_USER'] },
    ] },
],
routes : [
    { name:'login', path: '/login',   component: require('./layouts/Login.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'setpass', path: '/auth/set-password/:token',   component: require('./layouts/Password.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'loginsso', path: '/login/:authcode',   component: require('./layouts/Login.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'logout', path: '/logout',   component: require('./layouts/Logout.vue').default },
    { name: '404', path: '*', component: require('./layouts/404.vue').default },
    { name: '401', path: '/401', component: require('./layouts/401.vue').default },
    { path: '/', component: ChMain,
        children: [
            { name: 'home', path: '/', redirect: '/dashboard' },
            { name: 'dashboard', path: '/dashboard', 
                components: { default: ChDasboard, modal: ChSlider },
                children: [
                  { name: 'dash_slide_prev', path: 'sheet/:tsk_id', meta: { component: 'taskView' }, components: { content: ChEditCard } },
                  { name: 'dash_slide_edit', path: 'sheet/:tsk_id/modifier', components: { content: ChEditCard } },
                  //{ name: 'dash_slide_view', path: 'sheet/:tsk_id/action/:act_id', components: { content: ChViewAction } }
                ]
            },
            { name: 'map', path: '/map', 
                components: { default: ChMap, modal: ChSlider },
                children: [
                  { name: 'map_slide_loca', path: 'center/:latlng' },
                  { name: 'map_slide_prev', path: 'sheet/:tsk_id', meta: { component: 'taskView' }, components: { content: ChEditCard } },
                  { name: 'map_slide_edit', path: 'sheet/:tsk_id/modifier', components: { content: ChEditCard } },
                  { name: 'map_slide_add',  path: 'create/:tre_id', components: { content: ChEditCard } },
                  
                ]
            },
            { name: 'document', path: '/documents', 
                components: { default: ChDocument, modal: ChSlider },
                children: [
                  { name: 'doc_slide_prev', path: 'sheet/:tsk_id', meta: { component: 'taskView' }, components: { content: ChEditCard } },
                  { name: 'doc_slide_edit', path: 'sheet/:tsk_id/modifier', components: { content: ChEditCard } },
                  //{ name: 'doc_slide_view', path: 'sheet/:tsk_id/action/:act_id', components: { content: ChViewAction } }
                ]
            },
            { name: 'task', path: '/tasks', 
                components: { default: ChTask, modal: ChSlider },
                children: [
                  { name: 'task_slide_prev', path: 'sheet/:tsk_id', meta: { component: 'taskView' }, components: { content: ChEditCard } },
                  { name: 'task_slide_edit', path: 'sheet/:tsk_id/modifier', components: { content: ChEditCard } },
                  //{ name: 'dash_slide_view', path: 'sheet/:tsk_id/action/:act_id', components: { content: ChViewAction } }
                ]
            },
            { name: 'planning', path: '/planning', 
                components: { default: ChPlanning, modal: ChSlider },
                children: [
                  { name: 'plan_slide_prev', path: 'sheet/:tsk_id', meta: { component: 'taskView' }, components: { content: ChEditCard } },
                  { name: 'plan_slide_edit', path: 'sheet/:tsk_id/modifier', components: { content: ChEditCard } },
                  //{ name: 'dash_slide_view', path: 'sheet/:tsk_id/action/:act_id', components: { content: ChViewAction } }
                ]
            },
            { name: 'mapdict',       path: '/mapdict',         component: require('./pages/MapDict.vue').default },
            { name: 'alert',         path: '/alerts',          component: require('./pages/Alert.vue').default },
            //{ name: 'planning',      path: '/planning', component: require('./pages/Planning.vue').default },
            { name: 'importtask',    path: '/importtask', component: require('./pages/ImportTask.vue').default },
            { name: 'report',        path: '/reports',    component: require('./pages/Report.vue').default },
            
            { name: 'users',         path: '/users',             component: require('./pages/Users.vue').default },
            { name: 'useredit',      path: '/users/:usr_id',     component: require('./pages/User_edit.vue').default },
            
            { name: 'vehicle',       path: '/vehicles',          component: require('./pages/Vehicle.vue').default },
            { name: 'session',       path: '/sessions',          component: require('./pages/Session.vue').default },
            { name: 'session_edit',  path: '/sessions/:ses_id',  component: require('./pages/Session_edit.vue').default },
            { name: 'states',        path: '/states',            component: require('./pages/States.vue').default },
            { name: 'statesedit',    path: '/states/:sta_id',    component: require('./pages/States_edit.vue').default },
            { name: 'types',         path: '/types',             component: require('./pages/Types.vue').default },
            { name: 'typesedit',     path: '/types/:typ_id',     component: require('./pages/Type_edit.vue').default },
            { name: 'contacts',      path: '/contacts',          component: require('./pages/Contacts.vue').default },
            { name: 'contacts_view', path: '/contacts/:ctt_id',  component: require('./pages/Contacts_edit.vue').default },
            { name: 'trees',         path: '/trees',          component: require('./pages/Tree.vue').default },
            
            { name: 'importdata',   path: '/importdata', component: require('./pages/ImportData.vue').default },
            { name: 'jobs',         path: '/jobs', component: require('./pages/BackgroundJobs.vue').default },

            { name: 'forbidden',    path: '/forbidden', component: require('./layouts/403.vue').default },
            
        ]
    }
]


}