<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass" color="barrtextinfo">
            <v-breadcrumbs large divider=">" :items="contextStyle.breadcrumbs" :class="'pl-0'">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">{{ item.text }}</v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
    <!-- Formulaire -->
    <v-card fluid class="ma-4 pa-5 elevation-2 rounded-lg">
        <v-layout row wrap>
        <v-flex xs4 class="pa-2">
            <v-text-field prepend-icon="mdi-card-account-details-outline" label="Nom"    v-model="item.usr_lastName" ></v-text-field>
            <v-text-field prepend-icon="mdi-card-account-details-outline" label="Prénom" v-model="item.usr_firstName"></v-text-field>
            <v-text-field prepend-icon="mdi-sim-outline" label="Login"  v-model="item.usr_username"></v-text-field>
            <v-text-field prepend-icon="mdi-lock-outline" label="Mot de passe" type="password" v-model="item.usr_password" loading>
                <template v-slot:progress>
                    <v-progress-linear :value="progress" :color="color" height="3" absolute></v-progress-linear>
                </template>
            </v-text-field>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-text-field prepend-icon="mdi-phone" label="Tel" v-model="item.usr_phone"></v-text-field>
            <v-text-field prepend-icon="mdi-cellphone" label="Mobile" v-model="item.usr_mobile"></v-text-field>
            <v-text-field prepend-icon="mdi-email-outline" label="Courriel" v-model="item.usr_email"></v-text-field>
            <v-select v-if="!loading.fetchData" :items="roleList" item-value="value" v-model="item.usr_role" label="Role" >
                <template v-slot:prepend><v-icon>mdi-account-arrow-right-outline</v-icon></template>
            </v-select>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-container fluid class="text-right">
            <v-chip outlined color="blue">
                <v-icon left>mdi-information-outline</v-icon>Modification: {{ locDateFormat.formatLocale(item.usr_updated_at, 'fr') }}
            </v-chip>
            <v-btn outlined color="primmary" class="mt-3" @click="resetPassword()">
                <v-icon left>mdi-mail</v-icon>Envoyer le mail
            </v-btn>
            </v-container>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-select 
                item-text="typ_name" item-value="typ_id"
                :items="getCacheType('TSZ')" v-model="item.usr_typ_id_zone" label="Zone">
                <template v-slot:prepend><v-icon>mdi-code-brackets</v-icon></template>
            </v-select>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-select
                item-text="ses_label" item-value="ses_id"
                :items="getCacheSession()" v-model="item.usr_ses_id" label="Session" >
                <template v-slot:prepend><v-icon>mdi-calendar-month</v-icon></template>
            </v-select>
        </v-flex>
        </v-layout>
        <v-card-actions>
            <v-btn color="red" v-if="item.usr_uid" @click="deleteUser(item)"><v-icon title="Supprimer">mdi-delete</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" :loading="saveProgress" @click.native="saveUser()" :disabled="disabledSave">Enregistrer</v-btn>
            <v-btn color="primmary" href="/#/users/">Retour</v-btn>
        </v-card-actions>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'user_card',
    props: ['contextStyle'],
    data: function () {
        return {
            loading: { fetchData: false },
            saveProgress: false,         // Enregistrement en cours...
            roleList: [
                {'value': 'ADMIN_USER',      'text': '*Administrateur' },
                {'value': 'SUPER_USER',      'text': '*Superviseur' },
                //{'value': 'WORK_SUPERVISOR', 'text': 'Superviseur Atelier' },
                //{'value': 'TEAM_LEADER',     'text': 'Chef Atelier' },
                {'value': 'AGENT',           'text': '*Agent' },
                {'value': 'EXTERN_USER',     'text': 'Utilisateur externe' },
                //{'value': 'PARTNER_USER',    'text': 'Utilisateur partenaire' },
                //{'value': 'BASIC_USER',      'text': 'Utilisateur (aucun droit)' },
            ],
            item:{
              usr_id: 0, usr_uid:'', usr_username: '', usr_password: '', usr_ses_id: 0,
              usr_lastName: '', usr_firstName: '',
              usr_phone: '', usr_mobile: '', usr_email: '',
              usr_created_at: '2000-01-01', usr_updated_at: '',
              usr_isActive: 1, usr_sta_id_type: 'USR01', usr_role: 'BASIC_USER', usr_typ_id_zone: 'TSZ01'
            },
            locDateFormat: dateLocale, // Format de date
        }
    },
    computed: {
        progress () {
            return Math.min(100, this.item.usr_password.length * 12.5)
        },
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.progress / 50)]
        },
        disabledSave(){
            if(this.saveProgress){
                return true
            }
            if( this.item.usr_email.length > 2 ){
                return false
            } else {
                return true
            }
        }
    },
    mounted:function (){
        if( parseInt( this.$route.params.usr_id ) > 0 && this.$route.params.usr_id !== undefined ){
            this.loading.fetchData = true
            this.$http.get( '/users/' + this.$route.params.usr_id ).then( (response) => {
                if( response?.data?.data?.usr_role && this.roleList.find(o => o.value === response.data.data.usr_role) == undefined ){
                    this.roleList.push( {'value': 'WORK_SUPERVISOR', 'text': 'Superviseur Atelier' } )
                    this.roleList.push( {'value': 'TEAM_LEADER',     'text': 'Chef Atelier' } )
                    this.roleList.push( {'value': 'PARTNER_USER',    'text': 'Utilisateur partenaire' } )
                    this.roleList.push( {'value': 'EXTERN_USER',     'text': 'Utilisateur externe' } )
                }

                var prop
                for( prop in response.data.data ){
                    if( this.item[prop] !== undefined ){
                        this.item[prop] = response.data.data[prop] 
                    }
                }
                this.loading.fetchData = false
            })
            .catch( (error) => {
                this.$root.$toast({ color: 'warning', text: error })
            })
        }
    },
    methods: {
        saveUser () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( parseInt( this.$route.params.usr_id ) > 0 ){
                this.$http.put( '/users/' + this.$route.params.usr_id, this.item )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/users' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                })
            } else {
                this.$http.post( '/users/', this.item )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/users' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                })
            }
        },
        deleteUser(item){
            this.$root.$confirm('Supprimer l\'utilisateur (' + item.usr_id + ')', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.saveProgress = true
                    this.$http.delete( '/users/' + item.usr_id).then( () => {
                        this.$root.$toast({ color: 'success', text: 'Utilisateur supprimé !' })
                        this.saveProgress = false
                        this.$router.push({ path: '/users' })
                    }).catch( (error) => {
                        this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                        this.saveProgress = false
                    })
                }
            })
        },
        resetPassword(){
            this.$http.put( '/users/' + this.$route.params.usr_id + '/resetpass', {} )
            .then( () => {
                this.$root.$toast({ color: 'success', text: 'Mail envoyé !' })
            })
            .catch( (error) => {
                this.$root.$toast({ color: 'warning', text: error })
            })            
        },
        getCacheSession : cacheGetters.getSession,
        getCacheType: cacheGetters.getTypes,
    },
    components: { }
}
</script>